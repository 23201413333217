/*
 * @Author: swcbo
 * @Date: 2021-09-16 00:12:05
 * @LastEditors: swcbo
 * @LastEditTime: 2022-05-29 08:43:14
 * @FilePath: /zeekrlife/src/api/route.ts
 * @Description: route
 */

import { getAuth } from './../utils/index';
import request from '../plugins/request';

export const addUser = (data: any) =>
  request.post('api/user/save', {
    ...data,
    id: getAuth(),
  });

/**
 * 收集动作
 * type类型(1：造就巅峰时刻，2：场外打卡，3：实战碰撞，4：Z氪行驶分享)
 */
export const collect = (type: number, data?: any) =>
  request.post(`api/collect/collect`, { jkUserId: getAuth(), type, ...data });
export const checkSVIp = () =>
  request.post('/api/invite/checkSVIP', { userId: getAuth() });

/**
 * 邀请动作
 */
export const addInvite = (invitePhone: string, inviteName: string) =>
  request.post(`api/invite/addInvite`, {
    userId: getAuth(),
    invitePhone,
    inviteName,
  });

/**
 * 查询分享基本信息
 */
export const base = (code: string) =>
  request.post(`api/jkUser/authorization`, { code });

/**
 * 分享
 */
export const getWxConfig = (url: string) =>
  request.post('api/jkUser/share', { url, userId: getAuth() });

/**
 * 分享
 */
export const collectgetCollect = () =>
  request.post('api/collect/getCollect', { userId: getAuth() });

//   /api/user/save
// post
// 参数
// userName用户名称
// userPhone手机

export const saveUser = (data: { userName: string; userPhone: string }) =>
  request.post('api/user/save', {
    ...data,
    id: getAuth(),
  });

import { White } from '@/typings';
import { lazy } from 'react';
const Calculator = lazy(
  () => import(/* chunkName: "Calculator" */ '@/pages/calculator'),
);
const Home = lazy(() => import(/* chunkName: "Home" */ '@/pages/home'));
const NoFound = lazy(
  () => import(/* chunkName: NoFound */ '../components/NoFound'),
);
export const TabBarList: White.RouteTabBar[] = [];

const routes: White.RouteConfig[] = [
  {
    path: '/',
    component: Calculator,
    title: '北京市小客车指标家庭积分计算器',
  },
  {
    path: '/home',
    component: Home,
    title: '北京市小客车指标家庭积分计算器',
  },
  {
    path: '*',
    component: NoFound,
  },
];

export default [...routes];

/*
 * @Author: swcbo
 * @Date: 2021-09-16 00:12:05
 * @LastEditors: swcbo
 * @LastEditTime: 2022-05-29 08:54:18
 * @FilePath: /zeekrlife/src/App.tsx
 * @Description:
 */
import RouteRender from '@/routers/RouteRender';
import history from '@/utils/history';
import { useLayoutEffect } from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { base, getWxConfig } from './api/route';
import { getAuth, getCode, setAuth } from './utils';
import wx from 'weixin-js-sdk';

const App = () => {
  const appid = 'wx24fc3510eacdac92'; // 正式
  const redirectUrl = encodeURIComponent(window.location.href);
  const url =
    'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
    appid +
    '&redirect_uri=' +
    redirectUrl +
    '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
  useLayoutEffect(() => {
    const code = getCode();
    setAuth('');
    if (window.location.href.indexOf('3100') !== -1) {
      return;
    }
    if (!code) {
      // if (!['/', '/over'].includes(window.location.pathname)) {
      window.location.href = url;
      // }

      return;
    }
    const doConfig = async () => {
      const data = await getWxConfig(window.location.href);
      wx.config({
        debug: false,
        ...data,
        appId: appid,
        nonceStr: data.nonceStr,
        signature: data.signature,
        timestamp: data.timestamp,
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareAppMessage',
          'onMenuShareTimeline',
          'openLocation',
          'checkJsApi',
        ],
      });
      wx.ready(() => {
        wx.onMenuShareAppMessage({
          title: '北京市小客车指标家庭积分计算器',
          link: 'https://hycan.zhongdhy.top',
          type: 'link',
          imgUrl:
            'https://s3.xchuxing.com/xchuxing/forum/201905/21/051046owvftvw9ad29ev3w.jpg',
        });
        wx.onMenuShareTimeline({
          title: '北京市小客车指标家庭积分计算器',
          link: 'https://hycan.zhongdhy.top',
          imgUrl:
            'https://s3.xchuxing.com/xchuxing/forum/201905/21/051046owvftvw9ad29ev3w.jpg',
        });
        wx.updateAppMessageShareData({
          title: '北京市小客车指标家庭积分计算器',
          link: 'https://hycan.zhongdhy.top',
          type: 'link',
          imgUrl:
            'https://s3.xchuxing.com/xchuxing/forum/201905/21/051046owvftvw9ad29ev3w.jpg',
        });
        wx.updateTimelineShareData({
          title: '北京市小客车指标家庭积分计算器',
          link: 'https://hycan.zhongdhy.top',
          imgUrl:
            'https://s3.xchuxing.com/xchuxing/forum/201905/21/051046owvftvw9ad29ev3w.jpg',
        });
      });
    };
    if (!getAuth() && code) {
      base(code)
        .then(({ id }) => {
          setAuth(id);
          doConfig();
          const currentUrl = window.location.href;
          const cleanUrl = currentUrl.split('?')[0];
          window.history.pushState({}, document.title, cleanUrl);
        })
        .catch(() => {
          history.push('/');
        });
    } else {
      if (getAuth()) {
        doConfig();
      }
    }
  }, [url]);
  return (
    <>
      <Router history={history}>
        <RouteRender />
      </Router>
    </>
  );
};
export default App;
